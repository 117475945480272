import type { PaginatedReponse } from '@/components/composables/useDataTable/types'
import type { StatusResponse } from '../types'
import Vue from 'vue'

export type CreateCreditInsuranceDTO = {
  totalAmount: number
  remainingAmount: number
  expiresAt: string
  status: CreditInsuranceStatus
  base64: string
  extension: string
}

export type CreditInsuranceDTO = {
  totalAmount: number
  remainingAmount: number
  expiresAt: string
  status: CreditInsuranceStatus
  notes?: string
  id?: number
  path?: string
  client?: string
  currency?: string
  url?: string
  base64?: string
  extension?: string
}

export enum CreditInsuranceStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  EXPIRED = 'expired',
  USED_UP = 'used_up',
}

export type CreditInsuranceListRef = Vue & {
  paginateAndFetchData: () => Promise<PaginatedReponse<Record<string, unknown>>>
}

export type GetAllCreditInsuranceResponse = PaginatedReponse<CreditInsuranceDTO>
export type FindCreditInsuranceResponse = StatusResponse<{ creditInsurance: CreditInsuranceDTO }>
export type CreateCreditInsuranceResponse = StatusResponse<{ creditInsurance: CreditInsuranceDTO }>
export type UpdateCreditInsuranceResponse = StatusResponse<undefined>
export type DeleteCreditInsuranceResponse = StatusResponse<undefined>
