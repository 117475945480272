



































































import { formatDate } from '@/utils/helpers'
import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import UserStatusChip from '@/views/User/partials/UserStatusChip.vue'
import UserService from '@/services/user'
import CreateButton from '@/components/buttons/CreateButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import UsersSearchFilters from '@/views/User/partials/UsersSearchFIlters.vue'
import { StatisticsDTO } from '@/services/user/types'
import StatItem from '@/components/Stats/StatItem.vue'

export default defineComponent({
  setup() {
    const searchParams = reactive({ filters: { status: undefined, roleId: undefined } })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      UserService,
      searchParams,
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      UserService,
      searchParams,
    }
  },
  name: 'UsersList',
  components: { UsersSearchFilters, ShowButton, EditButton, CreateButton, UserStatusChip, DeleteButton, StatItem },
  data(): { statistics: StatisticsDTO; statsLoading: boolean } {
    return {
      statistics: {
        totalRecords: undefined,
        totalActive: undefined,
        totalPending: undefined,
        totalInactive: undefined,
      },
      statsLoading: true,
    }
  },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'fullName', text: this.$t('fullName'), sortable: true },
        { value: 'email', text: this.$t('email'), sortable: true },
        { value: 'phone', text: this.$t('phone'), sortable: true },
        { value: 'role', text: this.$t('role'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
  methods: {
    async fetchStatistics() {
      this.statsLoading = true
      const { data } = await UserService.statistics()
      this.statistics = data.data
      this.statsLoading = false
    },
  },
  watch: {
    totalRecords: function () {
      this.fetchStatistics()
    },
  },
})
