


































import Vue from 'vue'
import { UsersSearchFilters, UserStatus } from '@/views/User/types'
import ClearButton from '@/components/buttons/ClearButton.vue'
import RoleSelect from '@/components/selects/RoleSelect.vue'
import { StatisticsDTO } from '@/services/user/types'

export default Vue.extend({
  name: 'UsersSearchFilters',
  components: { RoleSelect, ClearButton },
  props: {
    searchParams: { type: Object, default: Function },
    statistics: {
      type: Object as () => StatisticsDTO,
      default: () => ({
        totalRecords: undefined,
        totalActive: undefined,
        totalPending: undefined,
        totalInactive: undefined,
      }),
    },
  },
  model: { prop: 'searchParams' },
  data: function () {
    return { UserStatus }
  },
  computed: {
    computedSearchParams: {
      get(): UsersSearchFilters {
        return this.searchParams
      },
      set(value: UsersSearchFilters): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    clear() {
      this.computedSearchParams.status = undefined
      this.computedSearchParams.roleId = undefined
    },
  },
})
