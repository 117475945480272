














































































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import PaymentForm from '@/views/Invoice/PaymentForm.vue'
import { ResponsePaymentDto } from '@/services/clientInvoice/types'
import ManualDeleteButton from '@/components/buttons/ManualDeleteButton.vue'
import InvoiceService from '@/services/clientInvoice'
import { formatDate, errorMessages } from '@/utils/helpers'

export default Vue.extend({
  name: 'InvoicePayments',
  components: {
    CancelButton,
    CardTitle,
    PaymentForm,
    ManualDeleteButton,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    unpaid: {
      type: Number,
      required: true,
    },
    clientId: {
      type: String,
      default: '',
    },
    paymentStatus: {
      type: String,
      default: '',
    },
    invoiceRemaining: {
      type: Number,
      required: true,
    },
    invoicePayments: {
      type: Array as () => ResponsePaymentDto[],
      required: true,
    },
  },
  data: function (): {
    dialog: boolean
  } {
    return {
      dialog: false,
    }
  },
  methods: {
    formatDate,
    closeModal() {
      this.dialog = false
    },
    refresh() {
      this.$emit('updated')
    },
    async deleteInvoicePayment(paymentId: number) {
      const loader = this.$loading.show()
      try {
        const response = await InvoiceService.delete(this.clientId, this.id, paymentId)
        if (response) {
          this.$emit('updated')
          this.$toast.success(response.data.message)
          this.closeModal()
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$tc('somethingWentWrong'))
      } finally {
        this.refresh()
        loader.hide()
      }
    },
  },
})
