




import Vue from 'vue'

export default Vue.extend({
  name: 'PaymentStatus',
  props: {
    status: {
      type: String,
      default: 'unpaid',
    },
  },
  computed: {
    color(): string {
      if (this.status === 'unpaid') return 'red red--text'
      if (this.status === 'paid') return 'green green--text'
      if (this.status === 'coveredByInsurance') return 'yellow yellow--text'
      if (this.status === 'swiftConfirmed') return 'blue blue--text'

      return 'red red--text'
    },
  },
})
