




import Vue from 'vue'

export default Vue.extend({
  name: 'PaymentStatus',
  props: {
    color: {
      type: String,
      default: 'red',
    },
    amount: {
      type: Number,
      default: -1,
    },
  },
})
