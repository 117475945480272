



























































































import Vue from 'vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { errorMessages } from '@/utils/helpers'
import InvoiceService from '@/services/clientInvoice'
import FiscalStatus from '@/views/Invoice/components/FiscalStatus.vue'
import { FiscalInvoicesDTO, ResponseClientInvoiceDTO } from '@/services/invoice/types'
import { InvoiceFiscalState, InvoiceFiscalStatus } from './types'

export default Vue.extend({
  name: 'InvoiceForm',
  components: {
    FiscalStatus,
    CancelButton,
    CardTitle,
    ShowButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    invoice: {
      type: Object as () => ResponseClientInvoiceDTO,
      required: true,
    },
  },
  data: function (): {
    dialog: boolean
    InvoiceFiscalStatus: any
    InvoiceFiscalState: any
  } {
    return {
      dialog: false,
      InvoiceFiscalStatus,
      InvoiceFiscalState,
    }
  },
  methods: {
    downloadFiscalPdf(fiscalInvoice: FiscalInvoicesDTO) {
      const linkSource = `data:application/pdf;base64,${fiscalInvoice.invoice_pdf}`
      const downloadLink = document.createElement('a')
      const fileName = 'fiscal_invoice_' + fiscalInvoice.invoice_no + '.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },

    async syncFiscalInvoice() {
      const loader = this.$loading.show()
      try {
        const response = await InvoiceService.syncInvoiceWithFiscalization(this.id)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          this.closeModal()
        }
      } catch (err) {
        this.$emit('created')
        this.$toast.error(this.$tc('somethingWentWrong'))
      } finally {
        loader.hide()
      }
    },

    async cancelFiscalInvoice() {
      const loader = this.$loading.show()
      try {
        const response = await InvoiceService.cancelFiscalInvoice(this.id)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          this.closeModal()
        }
      } catch (err: any) { // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$tc('somethingWentWrong'))
      } finally {
        loader.hide()
      }
    },

    closeModal(): void {
      this.dialog = false
    },
  },
})
