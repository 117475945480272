




































import Vue from 'vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'

export default Vue.extend({
  name: 'ManualDeleteButton',
  components: { CardTitle, SubmitButton },
  model: {
    prop: 'showModal',
  },
  props: {
    modalWidth: {
      type: String,
      default: '700',
    },
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    async deleteItem() {
      this.$emit('confirmed')
    },
  },
})
