













import Vue from 'vue'
import UserService from '@/services/user'
import { RoleDTO } from '@/services/user/types'

export default Vue.extend({
  name: 'RoleSelect',
  props: {
    role: {
      type: [Number, Array as () => RoleDTO[]],
      default: NaN,
    },
  },
  model: { prop: 'role' },
  data: function (): { items: RoleDTO[]; loading: boolean } {
    return {
      items: [],
      loading: false,
    }
  },
  async mounted(): Promise<void> {
    this.loading = true
    try {
      const { data } = await UserService.roles()
      if (data) this.items = data.data
    } finally {
      this.loading = false
    }
  },
  computed: {
    computedRole: {
      get(): number | RoleDTO[] {
        return this.role
      },
      set(value: number): void {
        this.$emit('input', value)
      },
    },
  },
})
