







import Vue from 'vue'
export default Vue.extend({
  name: 'StatItem',
  props: {
    width: { type: [String, Number], default: '20' },
    height: { type: String, default: '22' },
    textColorClass: { type: String, default: 'primary--text' },
    text: { type: [String, Number], default: '' },
    loading: { type: Boolean || undefined, default: false },
  },
})
