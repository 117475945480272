















































































import { formatDate } from '@/utils/helpers'
import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import UserService from '@/services/user'
import CreateButton from '@/components/buttons/CreateButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import UsersSearchFilters from '@/views/User/partials/UsersSearchFIlters.vue'
import InvoiceListService from '@/services/invoice/index'
import PaymentForm from '@/views/Invoice/PaymentForm.vue'
import InvoicePayments from '@/views/Invoice/InvoicePayments.vue'
import InvoiceStatus from '@/views/Invoice/components/InvoiceStatus.vue'
import InvoiceService from '@/services/clientInvoice'
import InvoiceForm from '@/views/Invoice/InvoiceForm.vue'
import StatItem from '@/components/Stats/StatItem.vue'

export default defineComponent({
  name: 'InvoicesList',
  setup() {
    const searchParams = reactive({ type: 'buy' })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      InvoiceListService,
      searchParams,
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      UserService,
      searchParams,
    }
  },
  components: {
    InvoiceForm,
    StatItem,
    UsersSearchFilters,
    ShowButton,
    EditButton,
    CreateButton,
    DeleteButton,
    InvoiceStatus,
    PaymentForm,
    InvoicePayments,
  },
  data() {
    return {
      selected: [],
      statistics: { paidAmount: 0, unpaidAmount: 0, sumAmount: 0 },
      statsLoading: true,
    }
  },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'procurement.id', text: this.$t('tenderId'), sortable: true },
        { value: 'procurement.name', text: this.$t('tenderTitle'), sortable: true },
        { value: 'procurement.isPublishedAt', text: this.$t('date'), sortable: true },
        { value: 'value', text: this.$t('total'), sortable: true, align: 'end' },
        { value: 'paid', text: this.$t('paid'), sortable: true, align: 'end' },
        { value: 'remaining', text: this.$t('remaining'), sortable: true, align: 'end' },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
  methods: {
    fetchInvoices(invoiceType: string) {
      this.searchParams.type = invoiceType
      this.fetchData()
    },
    async fetchStatistics() {
      this.statsLoading = true
      const { data } = await InvoiceService.allStatistics(this.searchParams.type)
      this.statistics = data.data
      this.statsLoading = false
    },
    refresh() {
      this.fetchStatistics()
      this.fetchData()
    },
  },
  watch: {
    totalRecords: function () {
      this.fetchStatistics()
    },
  },
})
