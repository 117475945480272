import api from '@/plugins/api'
import type { GetAllInvoicesResponse } from './types'
import {
  CreateInvoicePaymentsResponse,
  FindInvoicePaymentsResponse,
  FiscalizationSyncInvoiceResponse,
  InvoicePaymentsDTO,
  StatisticsInvoicesResponse,
} from './types'

const InvoiceService = {
  all(query = ''): Promise<GetAllInvoicesResponse> {
    return api().get(`/clients/invoices${query}`)
  },

  find(
    clientId: string | number,
    invoiceId: string | number,
    paymentId: string | number,
  ): Promise<FindInvoicePaymentsResponse> {
    return api().get(`/clients/${clientId}/invoices/${invoiceId}/payments/${paymentId}`)
  },

  create(payload: InvoicePaymentsDTO, clientId: string, invoiceId: number): Promise<CreateInvoicePaymentsResponse> {
    return api().post(`/clients/${clientId}/invoices/${invoiceId}/payments`, payload)
  },

  update(
    payload: InvoicePaymentsDTO,
    clientId: string,
    invoiceId: number,
    paymentId: number,
  ): Promise<CreateInvoicePaymentsResponse> {
    return api().put(`/clients/${clientId}/invoices/${invoiceId}/payments/${paymentId}`, payload)
  },

  delete(clientId: string, invoiceId: number, paymentId: number): Promise<CreateInvoicePaymentsResponse> {
    return api().delete(`/clients/${clientId}/invoices/${invoiceId}/payments/${paymentId}`)
  },

  statistics(clientId: string, type: string): Promise<StatisticsInvoicesResponse> {
    return api().get(`/clients/${clientId}/invoices/statistics?type=${type}`)
  },

  syncInvoiceWithFiscalization(invoiceId: number): Promise<FiscalizationSyncInvoiceResponse> {
    return api().post(`/fiscalization/invoices/${invoiceId}`)
  },

  cancelFiscalInvoice(invoiceId: number): Promise<FiscalizationSyncInvoiceResponse> {
    return api().post(`/fiscalization/invoices/${invoiceId}/cancel`)
  },

  allStatistics(type: string): Promise<StatisticsInvoicesResponse> {
    return api().get(`/clients/invoices/all/statistics?type=${type}`)
  },
}

export default InvoiceService
