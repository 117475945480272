









































































import { CreditInsuranceDTO, CreditInsuranceStatus } from '@/services/creditInsurance/types'
import Vue from 'vue'
import { PaymentDto } from '@/services/clientInvoice/types'
import DatePicker from '@/components/DatePicker.vue'
import CreditInsuranceService from '@/services/creditInsurance'

export default Vue.extend({
  name: 'CreditInsurance',
  components: { DatePicker },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Object as () => PaymentDto,
      required: true,
      default: () => {
        return {
          method: '',
          bank: '',
          amount: 0,
          date: '',
          swift: '',
          creditInsuranceId: -1,
        }
      },
    },
    tenderValue: {
      type: Number,
      required: true,
    },
  },
  model: {
    prop: 'creditInsuranceId',
  },
  data: function (): {
    rules: { required: (value: string) => string | boolean }
    creditInsurance: CreditInsuranceDTO
    creditInsurancesList: CreditInsuranceDTO[]
  } {
    return {
      rules: { required: (value: string) => !!value || this.$tc('validation.required') },
      creditInsurance: {
        totalAmount: 0,
        remainingAmount: 0,
        status: CreditInsuranceStatus.PENDING,
        expiresAt: '',
        notes: '',
        id: 0,
        path: '',
        client: '',
        currency: '',
        url: '',
      },
      creditInsurancesList: [],
    }
  },
  computed: {
    computedPayment: {
      get(): PaymentDto {
        return this.payment
      },
      set(value: PaymentDto): void {
        this.$emit('input', value)
      },
    },
    computedCreditInsurance: {
      get(): CreditInsuranceDTO {
        let selectedCreditInsurance = this.creditInsurancesList.find(
          (insurance) => insurance.id === this.payment.creditInsuranceId,
        )
        if (!selectedCreditInsurance) {
          return {
            totalAmount: 0,
            remainingAmount: 0,
            status: CreditInsuranceStatus.PENDING,
            expiresAt: '',
            notes: '',
            id: 0,
            path: '',
            client: '',
            currency: '',
            url: '',
          }
        }
        return selectedCreditInsurance
      },
    },
    computedRemainingAmount: {
      get(): number {
        let remaining = this.computedCreditInsurance.remainingAmount - this.tenderValue
        return remaining < 0 ? 0 : remaining
      },
    },
  },
  methods: {
    async fetchCreditInsurances(): Promise<void> {
      const { data } = await CreditInsuranceService(this.clientId).allActive('')
      this.creditInsurancesList = data.data.items
    },
  },
  mounted() {
    this.fetchCreditInsurances()
  },
})
