
































































import Vue from 'vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import SwiftDetails from '@/views/Invoice/components/SwiftDetails.vue'
import CreditInsurance from '@/views/Invoice/components/CreditInsurance.vue'
import { errorMessages } from '@/utils/helpers'
import InvoiceService from '@/services/clientInvoice'
import PaymentStatus from '@/views/Invoice/components/PaymentStatus.vue'
import ColoredAmount from '@/views/Invoice/components/ColoredAmount.vue'
import InvoiceDocumentsDropzone from '@/components/InvoiceDocumentsDropzone.vue'
import { InvoicePaymentsDTO, ResponsePaymentDto } from '@/services/clientInvoice/types'
import { CreditInsuranceDTO } from '@/services/creditInsurance/types'

export default Vue.extend({
  name: 'PaymentForm',
  components: {
    AddButton,
    SwiftDetails,
    CancelButton,
    CardTitle,
    CreditInsurance,
    ShowButton,
    EditButton,
    PaymentStatus,
    ColoredAmount,
    InvoiceDocumentsDropzone,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    paymentId: {
      type: Number,
      default: -1,
    },
    viewType: {
      type: String,
      default: 'show',
    },
    unpaid: {
      type: Number,
      required: true,
    },
    clientId: {
      type: String,
      default: '',
    },
    paymentStatus: {
      type: String,
      default: '',
    },
    invoiceRemaining: {
      type: Number,
      required: true,
    },
  },
  data: function (): {
    dialog: boolean
    clientInvoicePayment: InvoicePaymentsDTO
    paymentResponse: ResponsePaymentDto
    creditInsurancesList: CreditInsuranceDTO[]
  } {
    return {
      dialog: false,
      clientInvoicePayment: {
        payment: {
          method: 'swift',
          bank: '',
          amount: 0,
          date: '',
          swift: '',
          creditInsuranceId: 0,
        },
        documents: [],
      },
      paymentResponse: {
        id: -1,
        method: '',
        bank: '',
        amount: -1,
        date: '',
        swift: '',
        creditInsurance: {
          id: -1,
          totalAmount: '',
          remainingAmount: '',
          status: '',
          expirationDate: '',
          tenderValue: '',
          remainingAmountAfterTender: '',
          base64: '',
        },
        documents: [],
      },
      creditInsurancesList: [],
    }
  },
  computed: {
    tabs(): [string, string, string] {
      return [this.$tc('payment'), this.$tc('swift'), this.$tc('creditInsurance')]
    },
  },
  methods: {
    fileDeleted(key: any) { // eslint-disable-line
      const newDocuments = this.clientInvoicePayment.documents.filter((value, index) => {
        return index !== key
      })
      this.clientInvoicePayment.documents = newDocuments
    },

    async create(): Promise<void> {
      const loader = this.$loading.show()
      try {
        if (this.clientInvoicePayment.payment.method === 'credit_insurance') {
          let selectedCreditInsurance = this.creditInsurancesList.find(
            (el) => el.id === this.clientInvoicePayment.payment.creditInsuranceId,
          )
          if (selectedCreditInsurance && selectedCreditInsurance.remainingAmount) {
            this.clientInvoicePayment.payment.amount =
              selectedCreditInsurance.remainingAmount > this.unpaid
                ? this.unpaid
                : selectedCreditInsurance.remainingAmount
          }
        }
        const response = await InvoiceService.create(this.clientInvoicePayment, this.clientId, this.id)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          this.closeModal()
        }
      } catch (err: any) { // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$tc('somethingWentWrong'))
      } finally {
        loader.hide()
      }
    },

    async update(): Promise<void> {
      const loader = this.$loading.show()
      try {
        if (this.clientInvoicePayment.payment.method === 'credit_insurance') {
          let selectedCreditInsurance = this.creditInsurancesList.find(
            (el) => el.id === this.clientInvoicePayment.payment.creditInsuranceId,
          )
          if (selectedCreditInsurance && selectedCreditInsurance.remainingAmount) {
            this.clientInvoicePayment.payment.amount =
              selectedCreditInsurance.remainingAmount > this.unpaid
                ? this.unpaid
                : selectedCreditInsurance.remainingAmount
          }
        }
        const response = await InvoiceService.update(this.clientInvoicePayment, this.clientId, this.id, this.paymentId)
        if (response) {
          this.$emit('edited')
          this.$toast.success(response.data.message)
          this.closeModal()
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$tc('somethingWentWrong'))
      } finally {
        loader.hide()
      }
    },

    closeModal(): void {
      this.dialog = false
      this.clientInvoicePayment = {
        payment: {
          method: 'swift',
          bank: '',
          amount: 0,
          date: '',
          swift: '',
          creditInsuranceId: 0,
        },
        documents: [],
      }
    },
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await InvoiceService.find(this.clientId, this.id, this.paymentId)
        if (data.data) {
          this.paymentResponse = data.data
          this.clientInvoicePayment = {
            payment: {
              method: this.paymentResponse.method,
              bank: this.paymentResponse.bank,
              amount: this.paymentResponse.amount,
              date: this.paymentResponse.date,
              swift: this.paymentResponse.swift,
              creditInsuranceId: this.paymentResponse.creditInsurance ? this.paymentResponse.creditInsurance.id : -1,
            },
            documents: this.paymentResponse.documents,
          }
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$tc('somethingWentWrong'))
      } finally {
        loader.hide()
      }
    },
  },
})
