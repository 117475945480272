import api from '@/plugins/api'
import type {
  CreateUserResponse,
  DeleteUserResponse,
  FindUserResponse,
  GetAllRolesResponse,
  GetAllUsersResponse,
  UpdateUserResponse,
  GetStatisticsResponse,
} from './types'
import { StatusResponse } from '@/services/types'

const UserService = {
  all(query = ''): Promise<GetAllUsersResponse> {
    return api().get(`/users${query}`)
  },

  find(id: string | number): Promise<FindUserResponse> {
    return api().get(`/users/${id}`)
  },

  create(payload: Record<string, unknown>): Promise<CreateUserResponse> {
    return api().post('/users', payload)
  },

  update(id: string | number, payload: Record<string, unknown>): Promise<UpdateUserResponse> {
    return api().put(`/users/${id}`, payload)
  },

  delete(id: string | number): Promise<DeleteUserResponse> {
    return api().delete(`/users/${id}`)
  },

  statistics(): Promise<GetStatisticsResponse> {
    return api().get(`/users/statistics`)
  },

  roles(): Promise<GetAllRolesResponse> {
    return api().get(`/users/roles`)
  },

  resendActivationEmail(id: string | number): Promise<StatusResponse<undefined>> {
    return api().post(`/users/${id}/resend-activation-email`)
  },
}

export default UserService
