




import Vue from 'vue'

export default Vue.extend({
  name: 'FiscalStatus',
  props: {
    status: {
      type: String,
      default: 'unknown',
    },
  },
  computed: {
    color(): string {
      if (this.status === 'saved' || this.status === 'fiscalized' || this.status === 'cancelation_fiscalized') {
        return 'green green--text'
      }
      if (this.status === 'canceled' || this.status === 'waiting_cancelation') {
        return 'yellow yellow--text'
      }

      return 'red red--text'
    },
  },
})
