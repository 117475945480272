import api from '@/plugins/api'
import type { GetAllInvoicesResponse } from './types'

const InvoiceListService = {
  all(query = ''): Promise<GetAllInvoicesResponse> {
    return api().get(`/clients/invoices/all${query}`)
  },
}

export default InvoiceListService
