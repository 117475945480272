




































import Vue from 'vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'

export default Vue.extend({
  name: 'DeleteButton',
  components: { CardTitle, SubmitButton },
  model: {
    prop: 'showModal',
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    modalWidth: {
      type: String,
      default: '700',
    },
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    service: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    async deleteItem() {
      const loader = this.$loading.show()
      try {
        const response = await this.service.delete(this.id)

        if (response) {
          this.$emit('deleted')
          this.$toast.success(response.data.message)
          this.dialog = false
        }
      } catch (errors) {
        this.$toast.error(errors.response.data.message)
      } finally {
        loader.hide()
      }
    },
  },
})
