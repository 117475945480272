export enum InvoiceFiscalStatus {
  NOT_SAVED = 'not_saved',
  SAVED = 'saved',
  CANCELED = 'canceled',
}

export enum InvoiceFiscalState {
  NOT_FISCALIZED = 'not_fiscalized',
  FISCALIZED = 'fiscalized',
  WAITING_CANCELATION = 'waiting_cancelation',
  CANCELATION_FISCALIZED = 'cancelation_fiscalized',
}
