




















import { defineComponent } from '@vue/composition-api'
import { PaymentDto } from '@/services/clientInvoice/types'
import DatePicker from '@/components/DatePicker.vue'

type Document = {
  base64: string
  documentTypeId: string
  expiresAt: string
  extension: string
}

export default defineComponent({
  name: 'SwiftDetails',
  components: { DatePicker },
  props: {
    payment: {
      type: Object as () => PaymentDto,
      required: true,
      default: () => {
        return {
          method: '',
          bank: '',
          amount: 0,
          date: '',
          swift: '',
          creditInsuranceId: 0,
        }
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'payment',
  },
  data: function (): {
    document: Document
  } {
    return {
      document: {
        base64: '',
        documentTypeId: '',
        expiresAt: '',
        extension: '',
      },
    }
  },
  computed: {
    computedPayment: {
      get(): PaymentDto {
        return this.payment
      },
      set(value: PaymentDto): void {
        this.$emit('input', value)
      },
    },
  },
})
