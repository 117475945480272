import { RoleDTO } from '@/services/user/types'

export type UsersSearchFilters = {
  status?: RoleDTO[]
  roleId?: number[]
}

export enum UserStatus {
  ACTIVATED = 'activated',
  NOT_ACTIVATED = 'not_activated',
  DEACTIVATED = 'deactivated',
}
